

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import configReducer from './configSlice';
import thunkMiddleware from 'redux-thunk';

export default configureStore({
  reducer: {
    user: userReducer,
    config: configReducer
  },
  middleware: [thunkMiddleware]
});

export const rootReducer = combineReducers({
  user: userReducer,
  config: configReducer,
});

export type RootState = ReturnType<typeof rootReducer>