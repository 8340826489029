import { createSlice } from '@reduxjs/toolkit';


export const configSlice = createSlice({
    name: 'config',
    initialState: {
        isShowLoader: false,
        appsPerPage: 50,
        countries: []
    },
    reducers: {
        updateLoader: (state, data) => {
            state.isShowLoader = data.payload;
        },
        updateCountries: (state, data) => {
            state.countries = data.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { updateLoader, updateCountries } = configSlice.actions;

export default configSlice.reducer;