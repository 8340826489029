import API from './API';

const urls: any = {
    searchApp: '/search-app',
    app: '/app'
};

export function searchApp({ app, country, device = 'iphone' }: any) {
    let options: any = {
        params: {
            param: 'search',
            app,
            country,
            device
        }
    }
    return API.get(urls.app, options);
}

export function addApp(body: any) {
    return API.post(urls.app, body);
}

export function getTrackedAppsByPage(page: number = 1) {
    let options: any = {
        params: {
            param: "all",
            page
        }
    }
    return API.get(urls.app, options);
}

export function getShortsApps() {
    let options: any = {
        params: {
            param: "all-shorts"
        }
    }
    return API.get(urls.app, options);
}

