import { createSlice } from '@reduxjs/toolkit';


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        id: null,
        login: '',
        token: '',
        following_apps: []
    },
    reducers: {
        updateUser: (state, data) => {
            let user = data.payload;
            state.id = user.id;
            state.login = user.login;
            state.token = user.token;
            state.following_apps = user.following_apps;
        }
    }
});

// Action creators are generated for each case reducer function
export const { updateUser } = userSlice.actions;

export default userSlice.reducer;