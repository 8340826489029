import React from 'react';
import axios from 'axios';
import { logout } from "./authService";
import Store from '../store/store';
import { updateLoader } from '../store/configSlice';
import { message } from 'antd';

const prepareAPI = () => {
    const defaultOptions: any = {
        baseURL: 'http://204.48.22.77:5000',
        // baseURL: 'http://localhost:5000',
        method: 'get',
        headers: { "Access-Control-Allow-Origin": "*" }
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // By default set the token for any request
    instance.interceptors.request.use(function (config: any) {

        if (localStorage.getItem('token')) {
            config.headers.Authorization = localStorage.getItem('token');
        }
        if (!config.headers['Content-Type'] && !(config.body instanceof FormData)) {
            config.headers['Content-Type'] = 'application/json';
        }
        Store.dispatch(updateLoader(true));
        return config;
    });
    instance.interceptors.response.use(
        (response) => {
            Store.dispatch(updateLoader(false));
            return response;
        },
        (error) => {
            Store.dispatch(updateLoader(false));
            if (error.response && error.response.status === 401 && !error.config.url.startsWith(error.config.baseURL + 'auth')) {
                logout();
            }

            if (error.response && error.response.status === 406) {
                message.error(error.response.data);
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

export default prepareAPI();
