import React, { useEffect, useState } from "react";
import style from './main.module.scss';
import { Button, message, Modal } from "antd";
import { getKeywordsByApp, getReport } from "../../services/keywordService";
import AppsLoading from "../AppsLoading";
import { removeDuplicates } from "../../services/commonService";
import { updateCookie } from "../../services/authService";
import CookieModal from "../CookieModal";

export default function KeywordsModal({ apps, visible, country, onClose, repeatLimit, timeForGet }: any) {

    let [isLoaded, setIsLoaded] = useState<any>(false);
    let [keywords, setKeywords] = useState<any[]>([]);
    let [loadingPercentage, setLoadingPercentage] = useState<any>({ percent: 0, loaded: 0, total: 0 });
    const [isShowCookieModal, setIsShowCookieModal] = useState(false);

    let getSortedByScore = (arr: any[]) => {
        return arr.sort((a, b) => b.scoring - a.scoring);
    }

    useEffect(() => {
        loadKeywords();
    }, []);

    let loadKeywords = () => {

        if (apps.length > 0)
            getKeywordsRecursive(0);

    }

    let getKeywordsRecursive = (index = 0, repeat = 0) => {
        apps[index].status = 'loading';
        getKeywordsByApp(apps[index].id, country).then((response: any) => {
            keywords = [...keywords, ...response.data.keywords];
            keywords = removeDuplicates(keywords, 'phrase');
            setKeywords(keywords);
            calcPercentage();
            console.log('DATA', response.data);
            apps[index].status = 'success';


            if (response.data.keywords.length === 0 && repeat < repeatLimit) {
                repeat++;
                apps[index].status = 'repeat ' + repeat;

                setTimeout(() => {
                    getKeywordsRecursive(index, repeat);
                }, timeForGet);

                return;

            } else if (response.data.keywords.length === 0) {
                apps[index].status = 'empty';
            }


            if (index < apps.length - 1 && visible) {
                index++;
                apps[index].status = 'loading';
                setTimeout(() => {
                    getKeywordsRecursive(index);
                }, timeForGet);
            } else {
                setEndLoading();
            }

        }, (error: any) => {
            console.log('Error: ', error.response.status);
            if (error.response && error.response.status === 406) {
                onClose();
            }

            if (error.response.status === 403 && error.response.data?.error === 'update-cookie') {
                setIsShowCookieModal(true);
            }

            try {
                if (error.response.data && JSON.parse(error.response.data).error) {
                    if (JSON.parse(error.response.data).error.app) {
                        apps[index].status = JSON.parse(error.response.data).error.app;
                        if (index < apps.length - 1) {
                            index++;
                            getKeywordsRecursive(index);
                        } else {
                            setEndLoading();
                        }
                    } else {
                        message.error(JSON.parse(error.response.data).error);
                        onClose();
                    }
                }
            } catch { }
        });
    }

    let setEndLoading = () => {
        keywords = removeDuplicates(keywords, 'phrase');
        setKeywords(keywords);
        setIsLoaded(true);
    }

    let calcPercentage = () => {
        let loadedApps = apps.filter((app: any) => app.status === 'success').length;
        setLoadingPercentage(
            {
                percent: Math.round(100 * loadedApps / apps.length),
                loaded: loadedApps, total: apps.length
            }
        );
    }

    let getErrorsCount = () => {
        if (apps)
            return apps.filter((app: any) => app.status && app.status !== 'success').length;
    }


    let exportToExcel = () => {

        getReport({ country: country, keywords: getSortedByScore(keywords) }).then((response: any) => {

            if (response && response.headers["content-disposition"]) {
                const a = document.createElement("a");
                a.setAttribute("style", "display: none");
                document.body.appendChild(a);

                setKeywords([]);

                const blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                let url: any = URL.createObjectURL(blob);

                const fileName = response.headers["content-disposition"]
                    .substring(
                        response.headers["content-disposition"].indexOf("filename=") + 9
                    );

                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);

            }
        })
    }

    let sendCookie = (cookie: string) => {
        updateCookie({ cookie }).then((response: any) => {
            closeCookieModal();
            message.success('Retry get keywords, please!');
        });
    }

    let closeCookieModal = () => {
        setIsShowCookieModal(false);
    }

    return (
        <>
            {/* @ts-ignore */}
            <Modal
                title={'Keywords'}
                visible={visible}
                onCancel={() => onClose()}
                className={style["modal"]}
                footer={[
                    <Button onClick={() => onClose()} type="ghost" key="close">Close</Button>,
                    <Button disabled={!isLoaded} onClick={() => exportToExcel()} type="primary" key="export">Export</Button>
                ]}
            >
                <div className={style["info"]}>
                    <span>Keywords: {keywords.length}</span>
                    {/* <span className={style["error-field"]}>App Errors: {getErrorsCount()}</span> */}
                </div>
                <AppsLoading percent={loadingPercentage} apps={apps}></AppsLoading>
                {/* {
                    !isLoaded ?
                        <AppsLoading percent={loadingPercentage} apps={apps}></AppsLoading>
                        :
                        <div className={style["keywords-wrapper"]}>
                            <div className={style["header"]}>

                            </div>
                            <div className={style["keywords-list"]}>
                                {
                                    getSortedByScore(keywords).map((keyword: any, ind) => {
                                        return (
                                            <div key={ind + 1} className={style["keyword-item"]}>
                                                <span className={style["name"]}>{keyword.phrase}</span>
                                                <div className={style["score"]}>{keyword.scoring}</div>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>
                } */}
            </Modal>
            {
                isShowCookieModal ?
                    <CookieModal
                        visible={isShowCookieModal}
                        onSendCookie={(cookie: any) => sendCookie(cookie)}
                        onClose={() => closeCookieModal()}
                    ></CookieModal>
                    : <></>
            }
        </>
    )
}