
import React from "react";
import style from './main.module.scss';

export default function Pagination({ currentPage, totalPages, onSelectPage, className }: any) {

    let getTotalPages = () => {
        return Array.from(Array(totalPages).keys());
    }

    return (
        <div className={style["pagination"] + (className ? ' ' + className : '')}>
            {
                getTotalPages().map((page) => {

                    let classes = [style["btn"], style["page-btn"]];
                    if (currentPage === (page + 1))
                        classes.push(style["active-page"]);
                    return (
                        <button className={classes.join(' ')} key={page} onClick={() => onSelectPage(page + 1)}>
                            {page + 1}
                        </button>
                    )
                })
            }
        </div>
    );
}