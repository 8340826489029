import React from "react";
import { Menu } from "antd";
import { AppstoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function MainMenu() {

    return (
        <Menu>
            <Menu.Item key="mail" icon={<SearchOutlined />}>
                <Link to="">Search</Link>
            </Menu.Item>
            <Menu.Item key="app" icon={<AppstoreOutlined />}>
                <Link to="backlog">Backlog</Link>
            </Menu.Item>
        </Menu>
    )
}