import React from "react";
import { Routes, Route } from 'react-router-dom';
import AuthPage from "../pages/AuthPage";
import BacklogPage from "../pages/BacklogPage";
import SearchPage from "../pages/SearchPage";
import AdminGuard from "./AdminGuard";


export default function HomeRoutes() {
  return (
    <Routes>
      <Route path="/auth" element={<AuthPage></AuthPage>}></Route>
      <Route path="/" element={<AdminGuard><SearchPage></SearchPage></AdminGuard>}></Route>
      <Route path="/backlog" element={<AdminGuard><BacklogPage></BacklogPage></AdminGuard>}></Route>
    </Routes>
  );
}