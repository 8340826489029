import { SyncOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import HomePage from './pages/HomePage';
import { RootState } from './store/store';

function App() {
  const config = useSelector((state: RootState) => state.config);

  return (
    <div className="main-wrapper">
      <BrowserRouter>
        <HomePage></HomePage>
      </BrowserRouter>

      {
        config.isShowLoader ?
          <div className="loader">
            <SyncOutlined spin />
          </div>
          : <></>
      }
    </div>
  );
}

export default App;
