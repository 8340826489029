import { Button } from "antd";
import React, { useState } from "react";
import style from "./main.module.scss";
import { login } from '../../services/authService';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { updateUser } from "../../store/userSlice";
import { updateCountries } from "../../store/configSlice";

export default function AuthPage() {
    const dispatch = useDispatch();
    const [loginForm, setLoginForm] = useState({
        login: '',
        password: ''
    });

    let navigate = useNavigate();

    function submitLogin() {
        let body = {
            login: loginForm.login,
            password: loginForm.password
        };

        login(body).then((response: any) => {
            if (response.data && response.data.token) {
                localStorage.setItem('token', response.data.token);
                let user: any = { ...response.data.user };
                delete user.countries;
                dispatch(updateUser(user));
                dispatch(updateCountries(response.data.user.countries.map((country: any) => {
                    return { label: country.name, value: country.code, id: country.id }
                })));
                navigate('/');
            }
        });
    }

    let updateField = (field: string, value: string) => {
        let form = { ...loginForm };
        // @ts-ignore
        form[field] = value;
        setLoginForm(form);
    }

    return (
        <div className={style["auth-wrapper"]}>
            <div className="form">
                <div className="title">Log In</div>

                <div className="field">
                    <label className="label">Login</label>
                    <input
                        value={loginForm.login}
                        onInput={(e: any) => updateField('login', e.target.value)}
                    ></input>
                </div>
                <div className="field">
                    <label className="label">Password</label>
                    <input
                        value={loginForm.password}
                        onInput={(e: any) => updateField('password', e.target.value)}
                        type="password"
                    ></input>
                </div>

                <Button onClick={submitLogin} type="primary">Log in</Button>
            </div>
        </div>
    );
}