import API from './API';

const urls: any = {
    keyword: '/keyword'
};

export function getKeywordsByApp(id_app: number, country: any = null) {
    let options: any = {
        params: {
            param: 'get-by-app',
            id_app,
            country
        }
    }
    return API.get(urls.keyword, options);
}

export function getReport(app: any) {
    const options = {
        params: {
            param: "get-excel"
        },
        responseType: "blob" as "json",
        observe: "response" as "body",
    };
    return API.post(urls.keyword, app, options);
} 
