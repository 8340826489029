import { CheckOutlined, MinusOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Checkbox, Progress, Rate } from "antd";
import React, { useEffect } from "react";
import style from './main.module.scss';

export default function AppsLoading({
    apps,
    percent
}: any) {

    return (
        <div className={style['apps-wrapper']}>
            <div className={style["header"]}>
                <span>{percent.loaded}/{percent.total}</span>
                <Progress percent={percent.percent} />
            </div>

            <div className={style["list"]}>
                {
                    apps.map((app: any) => {
                        return (
                            <div key={app.id} className={style["item"]}>
                                <span className={style["title"]}>{app.title}</span>
                                <div className={style["status"]}>
                                    {app.status === 'loading' ?
                                        <SyncOutlined spin />
                                        : app.status === 'success' ?
                                            <CheckOutlined className={style["success"]} />
                                            : app.status ? <span className={style["error"]}>{app.status}</span> : <span>-</span>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}