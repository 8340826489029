import { MinusOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import Input from "antd/lib/input/Input";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppSegment from "../../components/AppSegment";
import KeywordsModal from "../../components/KeywordsModal";
import Pagination from "../../components/Pagination";
import { addApp, getShortsApps, getTrackedAppsByPage } from "../../services/appService";
import { RootState } from "../../store/store";
import { updateUser } from "../../store/userSlice";
import style from "./main.module.scss";


export default function BacklogPage() {

    const { countries, appsPerPage }: any = useSelector<any>((state: RootState) => state.config);

    let [apps, setApps] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isShowKeywords, setIsShowKeywords] = useState(false);
    const [appsForKeywords, setAppsForKeywords] = useState<any>([]);
    var [shortsApps, setShortsApps] = useState<any[]>([]);
    var [country, setCountry]: any = useState(localStorage.getItem('country') || 'md');
    const [selectedApps, setSelectedApps] = useState<any[]>([]);

    let [repeatLimit, setRepeatLimit] = useState<number>(2);
    let [timeForGet, setTimeForGet] = useState<number>(10000); // milliseconds


    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {

        if (localStorage.getItem('repeatLimit')) {
            // @ts-ignore
            setRepeatLimit(+localStorage.getItem('repeatLimit') || 2);
        }
        if (localStorage.getItem('timeForGet')) {
            // @ts-ignore
            setTimeForGet(+localStorage.getItem('timeForGet') || 10000);
        }
        getTrackedApps();

        getShortsApps().then((response: any) => {
            setShortsApps(response.data);
        });
    }, []);

    let getTrackedApps = (page: number = 1) => {
        getTrackedAppsByPage(page).then((response: any) => {
            if (response.data && response.data.apps) {
                setApps(response.data.apps);
                setTotalPages(response.data.totalPages);
                setCurrentPage(page);
            }
        });
    }


    let updateTimeForGet = (e: any) => {
        setTimeForGet(+e.nativeEvent.target.value);
        localStorage.setItem('timeForGet', e.nativeEvent.target.value);
    }

    let updateRepeatLimit = (e: any) => {
        setRepeatLimit(+e.nativeEvent.target.value);
        localStorage.setItem('repeatLimit', e.nativeEvent.target.value);
    }

    let getIndexApp = (page_index: number) => {
        return ((currentPage - 1) * appsPerPage) + page_index;
    }

    let untrackApp = (app: any) => {

        app.wait = true;

        addApp({ apps: [app] }).then((response: any) => {

            let arr: any[] = [...user.following_apps];
            for (let data of response.data) {
                if (data.status === "added")
                    arr.push(data.id);
                else if (data.status === "removed") {
                    setApps(apps.filter((app: any) => app.id !== data.id));
                    setShortsApps(shortsApps.filter((app: any) => app.id !== data.id));
                    arr = arr.filter((id: any) => id !== data.id);
                }

            }
            app.wait = false;

            dispatch(updateUser({ ...user, following_apps: arr }));
        });
    }

    let checkIsExist = (id: any) => {
        return !!user.following_apps.find((app_id: any) => id === app_id);
    }

    let untrackSelectedApps = () => {
        addApp({ apps: selectedApps }).then((response: any) => {

            let arr: any[] = [...user.following_apps];
            for (let data of response.data) {
                if (data.status === "added")
                    arr.push(data.id);
                else if (data.status === "removed") {
                    setApps(apps.filter((app: any) => app.id !== data.id));
                    shortsApps = shortsApps.filter((app: any) => app.id !== data.id);
                    setShortsApps([...shortsApps]);
                    arr = arr.filter((id: any) => id !== data.id);
                }

            }
            dispatch(updateUser({ ...user, following_apps: arr }));
            getTrackedApps(1);
            setSelectedApps([]);
        });
    }

    let filterOption = (input: any, option: any) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    let showKeywords = (apps: any) => {
        setIsShowKeywords(true);
        setAppsForKeywords(apps);
    };

    let closeModal = () => {
        setAppsForKeywords([]);
        shortsApps.map((app: any) => {
            delete app.status;
            return app;
        });
        setShortsApps(shortsApps);
        apps.map((app: any) => {
            delete app.status;
            return app;
        });
        setApps(apps);
        setIsShowKeywords(false);
    };

    let changeCountry = (event: any) => {
        setCountry(event);

        localStorage.setItem('country', event);

        apps.map((app: any) => {
            app.keywords = null;
            return app;
        });
    }

    let toggleSelectApp = (isChecked: boolean, id: string) => {
        let app: any = shortsApps.find((app: any) => app.id === id);
        if (!app)
            return;

        if (isChecked) {
            if (!selectedApps.find((app: any) => app.id === id)) {
                selectedApps.push(app);
                setSelectedApps([...selectedApps]);
            }
        } else {
            setSelectedApps(selectedApps.filter((a: any) => a.id !== id));
        }
        // console.log('SELECTEDS: ', selectedApps);
    }

    let isSeleceted = (id_app: number) => {
        return !!selectedApps.find(a => a.id === id_app);
    }

    let selectAllFromArray = (arr: any[]) => {
        if (arr.length === selectedApps.length) {
            setSelectedApps([]);
            return;
        }
        for (let app of arr) {
            toggleSelectApp(true, app.id);
        }
        // setPagedApps([...pagedApps]);
    }

    return (
        <div className={style["backlog-wrapper"]}>

            {
                isShowKeywords ?
                    <KeywordsModal
                        apps={[...appsForKeywords]}
                        country={country}
                        visible={isShowKeywords}
                        onClose={() => closeModal()}
                        repeatLimit={repeatLimit}
                        timeForGet={timeForGet}
                    ></KeywordsModal>
                    : <></>
            }

            <div className={style["header"]}>
                <h2>Tracked Apps</h2>
                <div className={style['limits-block']}>
                    <div className={style['field']}>
                        <label>Timeout query (ms)</label>
                        <Input type="number" min={0} value={timeForGet} onInput={(e: any) => updateTimeForGet(e)}></Input>
                    </div>
                    <div className={style['field']}>
                        <label>Repeat count</label>
                        <Input type="number" min={0} value={repeatLimit} onInput={(e: any) => updateRepeatLimit(e)}></Input>
                    </div>
                </div>
                <span>Selected: ({selectedApps.length})</span>
                <Select showSearch
                    onSelect={(event: any) => changeCountry(event)}
                    value={country}
                    filterOption={filterOption}
                    className={style['select-country']}
                >
                    {
                        countries.map((country: any) => {
                            return (
                                <Select.Option
                                    value={country.value}
                                    label={country.label}
                                    key={country.value}
                                >{country.label}</Select.Option>
                            );
                        })
                    }
                </Select>
            </div>
            <div className={style["actions"]}>
                {
                    apps.length ?
                        <Pagination
                            className={style["pagination"]}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onSelectPage={(page: number) => getTrackedApps(page)}
                        ></Pagination>
                        : <></>
                }
                <Button
                    onClick={() => selectAllFromArray(shortsApps)}
                    type="primary"
                    disabled={shortsApps.length === 0}
                >Select all</Button>
                <Button
                    disabled={selectedApps.length === 0}
                    className={style["btn"]}
                    onClick={() => showKeywords(selectedApps)}
                    type="primary"
                >Collect keywords</Button>
                <Button
                    disabled={selectedApps.length === 0}
                    className={style["btn"]}
                    onClick={() => untrackSelectedApps()}
                    type="ghost"
                >
                    <MinusOutlined />
                    Untrack selected
                </Button>
            </div>
            <div className={style["apps-list"]}>
                {
                    apps.map((app: any, index: number) => {
                        return (<AppSegment
                            app={app}
                            index={getIndexApp(index)}
                            toggleSelectApp={(event: any, id: string) => toggleSelectApp(event.target.checked, id)}
                            selectEnabled={true}
                            isSeleceted={isSeleceted(app.id)}
                            toggleTrackApp={() => untrackApp(app)}
                            getKeywords={() => showKeywords([{ ...app }])}
                            isExist={checkIsExist(app.id)}
                            key={app.id}
                            isWait={app.wait}
                        ></AppSegment>);
                    })
                }
            </div>


        </div>
    );

}