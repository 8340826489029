import React, { useEffect, useState } from "react";
import style from './main.module.scss';
import { Button, Modal } from "antd";

export default function CookieModal({ visible, onSendCookie, onClose }: any) {

    let [cookie, setCookie] = useState<any>('');


    return (
        <>
            {/* @ts-ignore */}
            <Modal
                title={'Set Cookie'}
                visible={visible}
                onCancel={() => onClose()}
                className={style["modal"]}
                footer={[
                    <Button onClick={() => onClose()} type="ghost" key="close">Close</Button>,
                    <Button onClick={() => onSendCookie(cookie)} type="primary" key="export">Send Cookie</Button>
                ]}
            >
                <div className="form">
                    <div className="field">
                        <label className="label">Cookie</label>
                        <input type="text" value={cookie} onInput={(event: any) => setCookie(event?.target.value)} />
                    </div>
                </div>
            </Modal>
        </>
    )
}