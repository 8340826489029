import React from "react";
import { useSelector } from "react-redux";
import MainMenu from "../../components/MainMenu";
import HomeRoutes from "../../services/HomeRoutes";
import { RootState } from "../../store/store";
import style from "./main.module.scss";

export default function HomePage() {
    const user = useSelector((state: RootState) => state.user);


    return (
        <div className={style["home-wrapper"]}>
            {user.id ? <MainMenu></MainMenu> : <></>}
            <div className={style["page-wrapper"]}>
                <div className={style["main-header"]}>
                    <div className={style["nav"]}>ASO</div>
                    <div className={style["user"]}>
                        {user.login}
                    </div>
                </div>
                <div className={style["page-content"]}>
                    <HomeRoutes></HomeRoutes>
                </div>
            </div>
        </div>
    );

}