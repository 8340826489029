import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

export default function AdminGuard(props: any) {

    const user = useSelector((state: any) => state.user);

    return (
        user.id ?
            props.children : <Navigate to={"/auth"}></Navigate>
    );

}