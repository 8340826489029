import { Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import AppSegment from "../../components/AppSegment";
import Pagination from "../../components/Pagination";
import { getAllCountries } from "../../services/countryService";
import { addApp, searchApp } from "../../services/appService";
import { RootState } from "../../store/store";
import { updateUser } from "../../store/userSlice";
import style from "./main.module.scss";
import CookieModal from "../../components/CookieModal";
import { updateCookie } from "../../services/authService";


export default function SearchPage() {

    const user = useSelector((state: RootState) => state.user);
    const { countries } = useSelector((state: RootState) => state.config);
    const dispatch = useDispatch();

    const [isShowCookieModal, setIsShowCookieModal] = useState(false);
    const [searchForm, setSearchForm]: any = useState({
        app: '',
        country: localStorage.getItem('country') || 'md',
        device: 'iphone'
    });
    // const [countries, setCountries] = useState([]);
    const [apps, setApps] = useState([]);
    // const [pagedApps, setPagedApps] = useState<any[]>([]);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [totalPages, setTotalPages] = useState(1);
    const [selectedApps, setSelectedApps] = useState<any[]>([]);

    useEffect(() => {
    }, []);

    let updateField = (field: string, value: string) => {
        searchForm[field] = value;
        if (field === 'country') {
            localStorage.setItem('country', value);
        }
        setSearchForm({ ...searchForm });
    }

    let setSearch = () => {
        if (!searchForm.app || !searchForm.country)
            return;
        searchApp({ ...searchForm }).then((response: any) => {
            setApps(response.data);
            setSelectedApps([]);
            // pagingApps(1);
        }, (error: any) => {
            if (error.response.status === 403 && error.response.data?.error === 'update-cookie') {
                setIsShowCookieModal(true);
            }
        });
        // navigate(`/result/${searchForm.app}/${searchForm.country}/${searchForm.device}`);
    };

    // useEffect(() => {
    //     console.log('APP USE');
    //     pagingApps(1);
    // }, [apps]);

    // let pagingApps = (page: number) => {
    //     setTotalPages(Math.ceil(apps.length / config.appsPerPage));
    //     setCurrentPage(page);
    //     let indexFrom = ((page - 1) * config.appsPerPage);
    //     let indexTo = indexFrom + config.appsPerPage;
    //     if (totalPages === page && (apps.length % config.appsPerPage !== 0))
    //         indexTo = indexFrom + apps.length % config.appsPerPage;
    //     console.log(indexFrom, ' : ', indexTo);
    //     setPagedApps(apps.slice(indexFrom, indexTo));
    //     console.log(pagedApps);
    // }

    // let getIndexApp = (page_index: number) => {
    //     return ((currentPage - 1) * config.appsPerPage) + page_index;
    // }

    let filterOption = (input: any, option: any) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    let toggleTrackApp = (apps: any[], isResetSelect = false) => {

        let arr: any[] = [...user.following_apps];
        addApp({ apps }).then((response: any) => {

            for (let data of response.data) {
                if (data.status === "added")
                    arr.push(data.id);
                else if (data.status === "removed")
                    arr = arr.filter((id: any) => id !== data.id);

            }

            if (isResetSelect)
                setSelectedApps([]);

            dispatch(updateUser({ ...user, following_apps: arr }));
            // setPagedApps(pagedApps);
        });
    }

    let toggleSelectApp = (isChecked: boolean, id: string) => {
        let app: any = apps.find((app: any) => app.id === id);
        if (!app)
            return;

        if (isChecked) {
            if (!selectedApps.find((app: any) => app.id === id) && checkSelectEnable(app)) {
                selectedApps.push(app);
                setSelectedApps([...selectedApps]);
            }
        } else {
            setSelectedApps(selectedApps.filter((a: any) => a.id !== id));
        }
    }

    let selectAllFromArray = (arr: any[]) => {
        if (arr.filter((app) => checkSelectEnable(app)).length === selectedApps.length) {
            setSelectedApps([]);
            return;
        }
        for (let app of arr) {
            toggleSelectApp(true, app.id);
        }
        // setPagedApps([...pagedApps]);
    }

    let checkIsExist = (id: any) => {
        return !!user.following_apps.find((app_id: any) => id === app_id);
    }

    let checkSelectEnable = (app: any) => {
        if (selectedApps.length === 0)
            return true;

        return checkIsExist(app.id) === checkIsExist(selectedApps[0].id);
    }

    let isUntrackedSelect = () => {
        return selectedApps.length === 0 || (selectedApps.length && !checkIsExist(selectedApps[0].id));
    }

    let isSeleceted = (id_app: number) => {
        return !!selectedApps.find(a => a.id === id_app);
    }


    let sendCookie = (cookie: string) => {
        updateCookie({ cookie }).then((response: any) => {
            closeCookieModal();
            setSearch();
        });
    }

    let closeCookieModal = () => {
        setIsShowCookieModal(false);
    }


    return (
        <div className={style["search-wrapper"]}>
            {
                isShowCookieModal ?
                    <CookieModal
                        visible={isShowCookieModal}
                        onSendCookie={(cookie: any) => sendCookie(cookie)}
                        onClose={() => closeCookieModal()}
                    ></CookieModal>
                    : <></>
            }
            <div className={style["search-form"]}>
                <input
                    onInput={(event: any) => updateField('app', event.target.value)}
                    className={style["search-field"]}
                    type="text"
                />
                <Select
                    showSearch
                    onSelect={(event: any) => updateField('country', event)}
                    defaultValue={searchForm.country}
                    filterOption={filterOption}
                >
                    {
                        countries.map((country: any) => {
                            return (
                                <Select.Option
                                    value={country.value}
                                    label={country.label}
                                    key={country.value}
                                >{country.label}</Select.Option>
                            );
                        })
                    }
                </Select>
                <Button
                    onClick={setSearch}
                    className="ant-search-btn"
                    type="primary"
                >Search</Button>
            </div>
            {
                apps.length > 0 ? (
                    <div className={style["func-block"]}>
                        <span>Selected Apps: ({selectedApps.length})</span>
                        <Button
                            disabled={selectedApps.length === 0}
                            onClick={() => toggleTrackApp(selectedApps, true)}
                            type="primary"
                        >{isUntrackedSelect() ? "Track" : "Untrack"} selected</Button>
                        <Button onClick={() => selectAllFromArray(apps)} type="primary">Select all from result</Button>
                    </div>
                ) : <></>
            }

            <div className={style["apps-list"]}>
                {
                    apps.map((app: any, index: number) => {
                        return (<AppSegment
                            toggleTrackApp={(app: any) => toggleTrackApp([app])}
                            toggleSelectApp={(event: any, id: string) => toggleSelectApp(event.target.checked, id)}
                            app={app}
                            isExist={checkIsExist(app.id)}
                            isSeleceted={isSeleceted(app.id)}
                            selectEnabled={checkSelectEnable(app)}
                            index={index}
                            key={app.id}
                        ></AppSegment>);
                    })
                }
            </div>
            {/* {
                apps.length ?
                    <Pagination totalPages={totalPages} currentPage={currentPage} onSelectPage={(page: number) => pagingApps(page)}></Pagination>
                    : <></>
            } */}

        </div>
    );

}