import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Rate } from "antd";
import React, { useEffect } from "react";
import style from './main.module.scss';

export default function AppSegment({
    app,
    index,
    isExist,
    isWait,
    isSeleceted,
    selectEnabled,
    toggleTrackApp,
    toggleSelectApp,
    getKeywords
}: any) {


    return (
        <div className={style['app-wrapper']}>
            <div className={style["index"]}>
                {index + 1}
            </div>
            <div className={style["info-column"]}>
                <figure className={style["icon"]}>
                    <img src={app.icon} alt="" />
                </figure>
                <div className={style["text-info"]}>
                    <span className={style["title"]}>{app.title}</span>
                    <span className={style["genre"]}>{app.subtitle}</span>
                    <span className={style["genre"]}>{app.genre}</span>
                    {app.rating ? <div className={style["rating"]}>
                        <Rate allowHalf disabled={true} defaultValue={app.rating.total.avg} />
                        <span className={style["cnt"]}>({app.rating.total.cnt})</span>
                    </div> : <></>}
                </div>
            </div>
            <div className={style["action-column"]}>
                {
                    getKeywords ? (
                        <Button disabled={isWait} onClick={() => getKeywords(app)} className={style['btn']} type="primary">
                            <span>Collect keywords</span>
                        </Button>
                    ) : (<></>)
                }
                {
                    toggleTrackApp ? (
                        <Button disabled={isWait} onClick={() => toggleTrackApp(app)} className={style['btn']} type={isExist ? "ghost" : "primary"}>
                            {isExist ? <MinusOutlined /> : <PlusOutlined />}
                            <span>{isExist ? 'Untrack' : 'Track'}</span>
                        </Button>
                    ) : (<></>)
                }
                {
                    toggleSelectApp ? (
                        <Checkbox disabled={!selectEnabled} checked={isSeleceted} onChange={(event) => toggleSelectApp(event, app.id)}></Checkbox>
                    ) : <></>
                }
            </div>
        </div>
    );
}