import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import Store from './store/store';
import { getUser } from './services/authService';
import { updateUser } from './store/userSlice';
import { updateCountries } from './store/configSlice';

const container: Element = document.getElementById('root') || document.createElement('div');
const root = ReactDOM.createRoot(container);

let render = () => {
  root.render(
    <Provider store={Store}>
      <App />
    </Provider>
  );
};

if (localStorage.getItem('token')) {
  getUser().then((response) => {
    if (response) {
      let user: any = { ...response.data };
      delete user.countries;
      console.log('USER: ', user);
      Store.dispatch(updateUser(user));
      Store.dispatch(updateCountries(response.data.countries.map((country: any) => {
        return { label: country.name, value: country.code, id: country.id }
      })));
      render();
    }
  }, (err) => {
    render();
  });
} else {
  render();
}


reportWebVitals();
