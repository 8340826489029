import API from './API';

const urls: any = {
    checkToken: '/check-token',
    login: '/login',
    updateCookie: "update_cookie"
};

export function login(body: any) {
    return API.post(urls.login, body);
}

export function getUser() {
    return API.get(urls.checkToken);
}

export function updateCookie(body: any) {
    return API.post(urls.updateCookie, body);
}

export function logout() {
    return '';
}

